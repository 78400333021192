import React, { useState, useEffect, useContext, lazy } from 'react';
import { useLocation } from "react-router-dom";

import { Context } from './Context/AuthContext';
import Header from './components/Header';
import Footer from './components/Footer';
import NavMenu from './components/NavMenu/NavMenu';

export default function Layout({ children }) {
    const [isLogin, setIsLogin] = useState(true);
    const location = useLocation()
    const { loading, authenticated } = useContext(Context);


     //#region Effects
     useEffect(() => {
        setIsLogin(location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    }, [location.pathname]);

    //#endregion Effects

    return (
        <React.Fragment>
            {
                !isLogin && authenticated ? (
                    <div id="wrapper">
                        <nav className="navbar-default navbar-static-side" role="navigation">
                            <NavMenu />
                        </nav>

                        <div id="page-wrapper" className="gray-bg dashboard-1">
                            <div className="row border-bottom">
                                <Header />
                            </div>
                            <div className="wrapper wrapper-content">
                                {children}
                            </div>
                            <div className="footer">
                                <Footer />
                            </div>
                        </div>
                    </div>) : (<div>{children}</div>)
            }
        </React.Fragment>
    )
}