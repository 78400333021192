import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Request } from '../../../scripts/Request';
import { Button } from "react-bootstrap";
import { HubObjects } from '../../../scripts/HubObjects';
import { FormGenerator } from '../../Form/FormGenerator';
import { SQL } from '../../../scripts/Database';
import { WaitObject } from '../../../scripts/Wait';
import './index.css';





export default function Home() {
    const component_name = "admin/home";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
 
    const _userRef = useRef();
    const timerIdleRef = useRef(new Date());
    const loadedRef = useRef(false);

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);


    //#region Effects
    useEffect(() => {
        let timer = setTimeout(() => { Init() }, 1000);

        return () => {
            clearTimeout(timer);
            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            }
        }
    },[]);

    useEffect(() => {
        if (user.token !== "") { 
            _userRef.current = user;  
        }
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);

    //#endregion Effects


    //#region Init 
    const Init = async() => {
        if(!loadedRef.current){
            loadedRef.current = true; 

            /*const db = new SQL();

            await db.Open("conversation").then(()=>{
                console.log("Db opened")
            }).catch(()=>{
                console.log("Db not opened")
            });
            
            
            await db.Create("cache", "id INTEGER PRIMARY KEY, name VARCHAR, date INT").then(()=>{
                console.log("Tb created")
            }).catch(()=>{
                console.log("Tb not created")
            });*/
            
            
            //await SQL.Clear("cache");

            //await db.Query("INSERT INTO cache (name,date) VALUES(?,?)", ["Query", new Date()], null);
           
            /*await db.Query("SELECT * FROM cache", [], (result) => {
                console.log(result);
            });*/

          
            /*WaitObject(new SQL()).then(async(db) => { 
                console.log("SQL Loaded");

                await db.Query("SELECT * FROM cache", []).then(result => {
                    console.log(result);
                }).catch(error => console.log(error));

            });*/

            //const db = new SQL();

            /*await new SQL().Query("SELECT * FROM cache", []).then(result => {
                console.log(result);
            }).catch(error => console.log(error));*/
        }    
     }
    //#endregion Init


    //#region Loaders
   
    //#endregion Loaders



    //#region Handlers

    //#endregion Handlers


    //#region HubObjects Handlers

    //#endregion HubObjects Handlers


    //#region Modal

    //#endregion Modal




    //#region Functions 
 
    //#endregion Functions




    return (
        <div>
            
            Home
        </div>
    )
}