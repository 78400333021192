import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { useNavigate, useParams } from 'react-router-dom';
import { Request } from '../../../scripts/Request';
import { HubObjects } from '../../../scripts/HubObjects';
import { AppServer } from '../../../scripts/Loadbalancer';
import '../../../libs/jstree/jstree'
import '../../../libs/jstree/themes/default/style.min.css';
import './index.css';


import { MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
  } from 'mdb-react-ui-kit';
  
import Breadcrumb from './BreadCrumb';
import { Dropdown } from '../../Plugins/Dropdown';
import { Wait, WaitObject } from '../../../scripts/Wait';
import { Chat } from '../../Chat';





export const Interactions = () => {
    const component_name = "Interactons";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [agent, setAgent] = useState();
    const [intent, setIntent] = useState({intent_id: 0, name: ""});
    const [showAutoGenerated, setShowAutoGenerated] = useState(false);
    const [showFrom, setShowFrom] = useState(false);
    const [optionSelected, setOptionSelected] = useState(0);
    const [intents, setIntents] = useState([]);
    const [fromInteractions, setFromInteractions]= useState([]);
    const [triggerInteractions, setTriggerInteractions]= useState([]);
    const [saveable, setSaveable] = useState(false);
    const [errors, setErrors] = useState([]);

    const _answerOrigin = {
        answer_id: "",
        mode: "0",
        phrase: "",
        regex: "",
        action: "0",
        api: "",
        api_header: "",
        trigger_category_id: "",
        trigger_id: "",
        index: "",
        parent_id: "",

        _triggers: []
    };


    const _fromOrigin = {
        category_id: "",
        answer_id: "",
        _answers: []
    };


    const _interactionOrigin = {
        answer_id: "",
        from: [{
            category_id: "",
            answer_id: "",
            _answers: []
        }],
        question: "",
        questions: [],
        auto_questions: [], 
        tipo: 1,
        isdefault: 0,
        name: "",
        index:0,
        answers: [{
            answer_id: "",
            mode: "0",
            phrase: "",
            regex: "",
            action: "0",
            api: "",
            api_header: "",
            trigger_category_id: "",
            trigger_id: "",
            index: "",
            parent_id: "",
    
            _triggers: []
        }]
    };


    const agentRef = useRef();
    const errorsRef = useRef([]);


    const [interaction, setInteraction] = useState(Object.assign({}, _interactionOrigin));


    const interactionRef = useRef();
    const firstLoadRef = useRef(false);
    const timerIdleRef = useRef(new Date());

    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    let { IntentId } = useParams();





    //#region Effects
    useEffect(() => {
        if(!firstLoadRef.current) {
            firstLoadRef.current = true;
            console.log("Interactions Rendered");

            Init();  
        }

        return () => {

            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    HubObjects.Clear(component_name);
                } catch(e) {}
            } 
        }
    }, []);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Init
    const Init = async() => {
        RegisterHub();
        Tree();
        await LoadAgentIntent();
        await LoadIntents();
    }
    //#endregion Init


    //#region Loaders
    const LoadAgentIntent = async() => {
        await Promise.resolve(new Request().Run("api/Interaction/Load?category_id=" + IntentId, "GET", "", user.token))
            .then((data) => {
                agentRef.current = data.experiment;
                setAgent(data.experiment);
                setIntent(data.category);
            });

        return;
    }


    const LoadIntents = async() => {
        setPageLoading(true);
        WaitObject(() => { return agentRef.current === undefined }).then(async() => {
            await Promise.resolve(new Request().Run("api/Interaction/Intents?experiment_id=" + agentRef.current.experiment_id, "GET", "", user.token))
            .then((data) => {
                setIntents(data);
                setPageLoading(false);
            });
        })
       
    }


    const LoadInteractions = async(intent_id, origem = 0, index = 0) => {
        console.log("LoadInteractions Init");
        if(intent_id === "") {
            if(origem === 0) {
                interactionRef.current.from[index].answer_id = "";
                interactionRef.current.from[index]._answers = [];
            } else {
                interactionRef.current.answers[index].trigger_id = "";
                interactionRef.current.answers[index]._triggers = [];
            }  
            setInteraction(interactionRef.current);
            Validate();
        } else {
            setPageLoading(true);
            await Promise.resolve(new Request().Run("api/Interaction/List?category_id=" + intent_id, "GET", "", user.token))
                .then((data) => {
                    if(origem === 0) {
                        interactionRef.current.from[index]._answers = data;
                        if(interactionRef.current.from[index].answer_id === "" || interactionRef.current.from[index].answer_id === undefined || interactionRef.current.from[index].answer_id === null) interactionRef.current.from[index].answer_id = data[0].answer_id;
                    } else {
                        interactionRef.current.answers[index]._triggers = data;
                        if(interactionRef.current.answers[index].trigger_id === "" || interactionRef.current.answers[index].trigger_id === undefined || interactionRef.current.answers[index].trigger_id === null) interactionRef.current.answers[index].trigger_id = data[0].answer_id;    
                    }   
                    setInteraction(interactionRef.current);
                    //console.log(interactionRef.current);
                    setPageLoading(false);
                    Validate();
                    forceUpdate();
                    console.log("LoadInteractions End");
                });
        }
    }


    const LoadInteraction = async(letter, id) => {
        setPageLoading(true);
        await Promise.resolve(new Request().Run("api/Interaction/GetInteraction?letter=" + letter + "&id=" + id, "GET", "", user.token))
             .then(async(data) => {
                //console.log(data);
                //console.log(_interaction);

                await LoadIntents();
                
                if(data.from != null && data.from.length > 0 ) {
                    data.from.map(async(item, i) => {
                        item = Object.assign(item, {_answers: []})
                    });
                }

                
                if(data.answers != null && data.answers.length > 0) {
                    data.answers.map(async(item, i) => {
                        item = Object.assign(item, {_triggers: []})
                    });
                }


                 interactionRef.current = {
                    answer_id: data.answer_id,
                    from:  data.from != null && data.from.length > 0 ? data.from : [Object.assign({},_fromOrigin)],
                    question: data.question,
                    questions: data.questions != null && data.questions.length ? data.questions :  [],
                    auto_questions: data.auto_questions != null && data.auto_questions ? data.auto_questions :  [],
                    tipo: data.tipo,
                    isdefault: data.isdefault,
                    name: data.name,
                    //options: data.options != null && data.options.length > 0 ? data.options : [Object.assign({},_option)],
                    answers: data.answers != null && data.answers.length > 0 ?  data.answers : [Object.assign({},_answerOrigin)]
                };


                if(data.from.length > 0 ) {
                    data.from.map(async(item, i) => {
                        await LoadInteractions(item.intent_id, 0, i);
                    });
                }


                  if(data.answers.length > 0) {
                    data.answers.map(async(item, i) => {
                        if(item.trigger_intent_id > 0) {
                            await LoadInteractions(item.trigger_intent_id, 1, i);

                        }
                    });
                }

                
                //console.log(interactionRef.current);
                setOptionSelected(0);
                setInteraction(interactionRef.current);
                setPageLoading(false);
                Validate();
             });
 
     }


    const Delete = async(letter, intent_id, answer_id) => {
        //console.log(letter, intent_id, answer_id);
        if(letter === "a" || letter === "q") {
            setPageLoading(true);
            await Promise.resolve(new Request().Run("api/Interaction/Delete?letter=" + letter + "&category_id=" + intent_id + "&category_id=" + answer_id, "GET", "", user.token))
                .then((data) => {
                    setPageLoading(false);
                });
        } 
        else {
            window.swal("Alerta","Não é possível apagar essa intenção","error");
        }
       
        return true;
    }


    const CheckDefault = async(answer_id) => {
        await Promise.resolve(new Request().Run("api/Interaction/CheckDefault?experiment_id=" + agent.experiment_id, "GET", "", user.token))
            .then((data) => {
                if(data !== "" && data !== undefined && data !== null && data !== "no_result") {
                    if(data.toString() !== answer_id.toString()) {
                        window.swal("Alerta","Já existe uma interação inicial configurada, se prossegir, essa será a nova interação inicial","warning");
                    }
                } 
            }).catch(() => {});
    }
    //#endregion Loaders


    //#region Saves
    const Validate = () => {
        errorsRef.current = [];
        let _interactionObj = Object.assign({}, interactionRef.current); //interactionRef.current; //Object.assign({}, interaction);
        let _valid = true;


        if(_interactionObj.from != null && _interactionObj.from.length > 0) {
            _interactionObj.from.map((item, i) => {
                if(item.category_id !== 0 &&  item.category_id !== "" && item.category_id !== null && item.category_id !== undefined && (item.answer_id === 0 || item.answer_id === "" || item.answer_id === undefined || item.answer_id === null)) {
                    _valid = false;
                    errorsRef.current.push("Interação de origem não identificada.");
                }
            });
        }

        if(_interactionObj.tipo === 1) {
            _interactionObj.isdefault = 0;

            _interactionObj.answers.map((item, i) => {
                item.index = 0;
                if(item.phrase === "" || item.phrase === undefined || item.phrase === null) {
                    _valid = false;
                    errorsRef.current.push("Não há resposta para a interação.");
                } 

                /*if(item.intent_id && item.intent_id != IntentId) {
                    _valid = false;
                    errorsRef.current.push("Essa interação pertence a outra intenção!");
                }*/
            });

        } else if(_interactionObj.tipo === 0) {
            if(_interactionObj.answers != null && _interactionObj.answers.length > 1) {
                _interactionObj.answers.map((item, i) => {
                    item.index = i+1;
                    if(item.phrase === "" || item.phrase === undefined || item.phrase === null) {
                        _valid = false;
                        errorsRef.current.push("Não podem haver opções em branco.");
                    }
                });
            } else {
                _valid = false;
                errorsRef.current.push("Devem haver pelo menos 2 opções de escolha.");
            }
        }

        if(_interactionObj.questions != null && _interactionObj.questions.length > 0) {
            if(_interactionObj.question === "" || _interactionObj.question === undefined || _interactionObj.question === null) {
                _valid = false;
                errorsRef.current.push("A entrada do usuário deve ser preenchida.");
            }

            _interactionObj.questions.map((item, i) => {
                if(item.question === "" || item.question === undefined || item.question === null) {
                    _valid = false;
                    errorsRef.current.push("Não podem haver entradas do usuário em branco.");
                }
            });
        }


        if(_interactionObj.auto_questions != null && _interactionObj.auto_questions.length > 0) {
            _interactionObj.auto_questions.map((item, i) => {
                if(item.question === "" || item.question === undefined || item.question === null) {
                    _valid = false;
                    errorsRef.current.push("Não podem haver entradas auto geradas em branco.");
                }
            });
        }


        if(_interactionObj.answers != null && _interactionObj.answers.length > 0) {
            _interactionObj.answers.map((item, i) => {
                if(item.trigger_intent_id > 0 && (item.trigger_id === "" || item.trigger_id === undefined || item.trigger_id === null)) {
                    _valid = false;
                    errorsRef.current.push("Interação de destino não identificada.");
                } 
            });
        } else {
            _valid = false;
            errorsRef.current.push("Não há respostas para a interação.");
        }

        interactionRef.current = _interactionObj;

        console.log(errorsRef.current.join('\n'))
        //window.swal("Alerta", errorsRef.current.join('\n'), "error");
        setErrors(errorsRef.current);
        setSaveable(_valid);
        setInteraction(interactionRef.current);
        return _valid;
    }


    const _HandleSave = async() => {
        //console.log(interactionRef.current);
        if(!Validate()) {
            window.swal("Alerta", errorsRef.current.join('\n'), "error");
        }

        let _data = Object.assign({experiment_id: agentRef.current.experiment_id, category_id: IntentId}, interactionRef.current);

        
        setPageLoading(true);

        await Promise.resolve(new Request().Run("api/Interaction/Save?category_id=" + IntentId, "POST", _data, user.token))
            .then((data) => {
                setPageLoading(false);

                if(data.toString() === "loaded") {
                    window.swal("Sucesso", "Interação salva com sucesso", "success");
                    interactionRef.current = Object.assign({},_interactionOrigin);
                    //console.log(interactionRef.current)
                    setInteraction(interactionRef.current);
                    forceUpdate();
                    $('#layers_tree').jstree(true).refresh();
                } else {
                    window.swal("Alerta", "Não foi possível salvar interação", "error");
                }
            });
            
    }
    //#endregion Saves


    //#region HubObjects
     const RegisterHub = () => {

     }
    //#endregion HubObjects


    //#region Tree
    const Tree = () => {
        $.ajaxSetup({
            beforeSend: function(request) {
                request.setRequestHeader("Authorization", user.token);
            }
        });

        const customMenu = (node) => {
            let items = {}
            
            items = {
                'item1': {
                    'label': 'Editar Interação',
                    'action': (data) => {
                        let inst = $.jstree.reference(data.reference);
                        let obj = inst.get_node(data.reference);

                        let arr = obj.id.toString().split('_');
                        if(arr.length >= 2) {
                            let type = arr[0];
                            let id = arr[arr.length - 2];
                            //console.log(type, id);
                            LoadInteraction(type, id);
                        }
                    }
                },
                'item2': {
                    'label': 'Adicionar Interação Subsequente',
                    'action': (data) => {
                        let inst = $.jstree.reference(data.reference);
                        let obj = inst.get_node(data.reference);
                        
                        interactionRef.current = {
                            answer_id: "",
                            from: [{
                                category_id: "",
                                answer_id: "",
                                _answers: []
                            }],
                            question: "",
                            questions: [],
                            auto_questions: [], 
                            tipo: 1,
                            isdefault: 0,
                            name: "",
                            index:0,
                            answers: [{
                                answer_id: "",
                                mode: "0",
                                phrase: "",
                                regex: "",
                                action: "0",
                                api: "",
                                api_header: "",
                                trigger_category_id: "",
                                trigger_id: "",
                                index: "",
                                parent_id: "",
                        
                                _triggers: []
                            }]
                        };

                        
                        let arr = obj.id.toString().split('_');  
                        let id = arr[arr.length - 2];

                        interactionRef.current.from[0].category_id_id = parseInt(IntentId);
                        interactionRef.current.from[0].answer_id = parseInt(id);
                        LoadInteractions(IntentId);

                        setInteraction(interactionRef.current);
                        forceUpdate();
                    }
                },
                'item3': {
                    'label': 'Excluir Interação',
                    'action': async(data) => {
                        let inst = $.jstree.reference(data.reference);
                        let obj = inst.get_node(data.reference);

                        let arr = obj.id.toString().split('_');  
                        let type = arr[0];
                        let id = arr[arr.length - 2];
                        
                        Delete(type, IntentId, id);

                        interactionRef.current = {
                            answer_id: "",
                            from: [{
                                category_id: "",
                                answer_id: "",
                                _answers: []
                            }],
                            question: "",
                            questions: [],
                            auto_questions: [], 
                            tipo: 1,
                            isdefault: 0,
                            name: "",
                            index:0,
                            answers: [{
                                answer_id: "",
                                mode: "0",
                                phrase: "",
                                regex: "",
                                action: "0",
                                api: "",
                                api_header: "",
                                trigger_category_id: "",
                                trigger_id: "",
                                index: "",
                                parent_id: "",
                        
                                _triggers: []
                            }]
                        };

                        setInteraction(interactionRef.current);
                        forceUpdate();
                        $('#layers_tree').jstree(true).refresh();
                    }
                }
            }
            
            
            return items;
        }


         let jstree = $('#layers_tree').jstree({
            "core": {
                "animation": 1,
                "check_callback": true,
                "themes": { "stripes": false },
                'data': {
                     'url': function (node) {
                         return AppServer() + 'api/Interactions/Tree?n=' + IntentId + '&p=' + node.parents.length;
                     },
                     'data': function (node) {
                         return { 'id': node.id };
                     }
                 }
            },
            "types": {
                "#": {
                    "max_children": 2000,
                    "max_depth": 20,
                    "valid_children": ["root"]
                },
                /*"root": {
                    //"icon": "/static/3.3.10/assets/images/tree_icon.png",
                    "valid_children": ["default"]
                },
                "default": {
                    "valid_children": ["default", "file"]
                },
                "file": {
                    "icon": "glyphicon glyphicon-file",
                    "valid_children": []
                }*/
            },
            'checkbox': {
                three_state: true,
                /*cascade: ''*/
            },
            'contextmenu': {
                'items': customMenu,
                 select_node: false
            },
            "plugins": [
                /*"checkbox", */"changed", "contextmenu"
            ]
        });


        jstree.bind("hover_node.jstree", function (e, data) {
            
        });


        jstree.bind("select_node.jstree", function (e, data) {
            let arr = data.node.id.toString().split('_');
            if(arr.length >= 2) {
                let type = arr[0];
                let id = arr[arr.length - 2];
                //console.log(type, id);
                LoadInteraction(type, id);
            }
        });


        jstree.bind('changed.jstree', function (e, data) {
            let nodes = $('#layers_tree').jstree(true).get_selected();
            let _nodes = [];
           
        });
    }



    //#endregion Tree


    //#region Handlers
    //#region Origem
    const _HandleChange = (event) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj[event.target.name] = event.target.value;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleFromChange = (event, index, field) => {
        interactionRef.current = Object.assign({}, interaction);
        interactionRef.current.from[index][field] = event.target.value;

        if(field === "intent_id"){
            LoadInteractions(event.target.value, 0, index);
        } 
    }


    const _HandleAddFrom = () => {
        interactionRef.current = Object.assign({}, interaction);
        interactionRef.current.from.push(Object.assign({}, _fromOrigin));
        setInteraction(interactionRef.current);
        setShowFrom(true);
        Validate();
    }


    const _HandleRemoveFrom = (index) => {
        interactionRef.current = Object.assign({}, interaction);
        interactionRef.current.from.splice(index, 1);
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleShowFrom = (b) => {
        setShowFrom(!b);
    }
    //#endregion Origem


    //#region Question
    const _HandleChangeQuestion = (event, index) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.questions[index].question = event.target.value;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleChangeAutoQuestion = (event, index) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.auto_questions[index].question = event.target.value;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleAddQuestion = () => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.questions.push({question:""})
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleRemoveQuestion = (index) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.questions.splice(index, 1);
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleRemoveAutoQuestion = (index) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.auto_questions.splice(index, 1);
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleAutoGenerate = async() => {
        let _interactionObj = Object.assign({}, interaction);
        
        if( _interactionObj.question === "") {
            window.swal("Alerta", "Primeiro preencha a entrada padrão do usuário!", "error")
            return;
        }


        await Promise.resolve(new Request().Run("api/Interaction/Generate", "POST", { phrase: _interactionObj.question }, user.token))
            .then((data) => {
                if(data.length > 0) {
                    data.map((item, i) => {
                        let index = _interactionObj.auto_questions.findIndex(v => v.question === item);
                        //console.log(index);
                        if(index === -1) _interactionObj.auto_questions.push({question:item});
                    });

                    interactionRef.current = _interactionObj;
                    setInteraction(interactionRef.current);
                    Validate();
                    //console.log(interactionRef.current);
                }
            });
    }


    const _HandleShowAutoGenerate = (b) => {
        setShowAutoGenerated(!b);
    }

    //#endregion Question


    //#region Answer
    const _HandleTipoClick = (v) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.tipo = v;
        setOptionSelected(0);
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleDefaultClick = (event) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.isdefault = event.target.checked ? 1 : 0;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();

        if(_interactionObj.isdefault === 1) {
            CheckDefault(interactionRef.current.answer_id);
        }
    }

    const _HandleAnswerChange = (e, index, field) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.answers[index][field] = e.target.value;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const DropAction = (txt, index) => {
        let _interactionObj = Object.assign({}, interaction);
        _interactionObj.answers[index].regex = txt;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
    }


    const _HandleTriggerChange = (e, index, field) => {
        interactionRef.current = Object.assign({}, interaction);
        interactionRef.current.answers[index][field]= e.target.value;


        if(field === "trigger_intent_id"){
            LoadInteractions(e.target.value, 1, index);
        }
    }
    //#endregion Answer


    //#region Options
    const _HandleAddOption = (item) => {
        let _interactionObj = Object.assign({}, interaction);
        //_interaction.options.push(Object.assign({},_option));
        let _answ = Object.assign({},_answerOrigin);
        //_answ.index = _interaction.answers.length;
        _interactionObj.answers.push(_answ);
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
    }


    const _HandleChangeOption = (event, index) => {
        //console.log(_interactionOrigin);
        let _interactionObj = Object.assign({}, interaction);
        //_interaction.options[index].phrase = event.target.value;
        _interactionObj.answers[index].phrase = event.target.value;
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
        Validate();
    }


    const _HandleRemoveOption = (index) => {
        let _interactionObj = Object.assign({}, interaction);
        //_interaction.options.splice(index, 1);
        _interactionObj.answers.splice(index, 1);
        interactionRef.current = _interactionObj;
        setInteraction(interactionRef.current);
    }


    const _HandleFocusOption = (e, index) => {
        setOptionSelected(index);
    }
    //#endregion Options
    //#endregion Handlers


    return (
        <div className="Interaction">
            <div className="row">
                <div className="col-lg-6">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            { agent && (<Breadcrumb title="Interações" list={[{name: "Agente", url:""}, {name: agent.name, url:"/Agents/Cadastro/"+agent.experiment_id }, {name: "Intenção", url:""}, {name: intent.name, url:"/Intents/Cadastro/"+agent.category_id}]}  />) }
                        </div>

                       <div className="ibox-content">
                            {
                                interaction.from.length > 0 && interaction.from.map((from, j) => (
                                    <section id="SOrigem" className={(!showFrom && j > 0 ? 'hide' : '')}>
                                        { j > 0 && (<hr className="hr" />) }
                                        <label>Intenção de origem</label> <br />
                                        <select className={'from form-control'} id={'from_category_id'+j} name={'from_category_id'+j} onChange={(event) =>_HandleFromChange(event, j, 'category_id')}>
                                            <option value="">Nenhuma intenção selecionada</option>
                                            {
                                                intents.length > 0 && intents.map((item, i) => (
                                                    <option key={'opt_im'+i} value={item.category_id} selected={item.category_id===interaction.from[j].category_id}>{item.name}</option>
                                                ))
                                            }
                                        </select>

                                        <br />
                                        <label>Interação de origem</label> <br />
                                        <select className="from form-control" id={'from_answer_id'+j} name={'from_answer_id'+j} onChange={(event) =>_HandleFromChange(event, j, 'answer_id')} disabled={interaction.from[j].intent_id===''}>
                                            {
                                                interaction.from[j]._answers.length > 0 && interaction.from[j]._answers.map((item, i) => (
                                                    <option key={'opt_int'+j+'_'+i} value={item.answer_id}selected={item.answer_id===interaction.from[j].answer_id}>{item.phrase}</option>
                                                ))
                                            }
                                        </select>
                                        
                                    </section>
                                ))
                            }

                            <div className="right">
                                <span>[ <a onClick={_HandleAddFrom}>Adicionar multiplas origens</a> ]</span> 
                                <span className={'m_origens ' + (interaction.from.length>1&&!showFrom?'':'hide')}>[ <a onClick={() => _HandleShowFrom(showFrom)}>Mostrar</a> ]</span> 
                                <span className={'m_origens ' + (interaction.from.length>1&&showFrom?'':'hide')}>[ <a onClick={() => _HandleShowFrom(showFrom)}>Esconder</a> ]</span> 
                            </div>  
                            <br />
                        </div>   
                    </div> 


                    <div className="ibox float-e-margins">
                        <div className="ibox-content">


                            <section id="SQuestion">
                                <label>Entrada do usuário</label> <br />
                                <div className="input-group"><input type="text" className="from form-control" id="question" name="question" value={interaction.question}  onChange={_HandleChange}/> <span className="input-group-append" > <button type="button" className="btn btn-primary" onClick={_HandleAddQuestion}> adicionar</button> </span></div>
                                {
                                    interaction.questions.map((item, i) => (
                                        <div key={'question'+i} className="input-group"><input type="text" className="from form-control" id={'question'+i} name={'question'+i} value={item.question} onChange={(event) => _HandleChangeQuestion(event, i)} /> <span className="input-group-append" > <button type="button" className="btn btn-danger" onClick={() =>_HandleRemoveQuestion(i)}> <i className="fa fa-trash"></i></button> </span></div>
                                    ))
                                }
                                <div className="right">
                                    <span>[ <a onClick={_HandleAutoGenerate}>Auto Gerar similares</a> ]</span> 
                                    <span className={(showAutoGenerated||(interaction.auto_questions.length===0)?'hide':'')}>[ <a onClick={() => _HandleShowAutoGenerate(showAutoGenerated)}>Mostrar</a> ]</span> 
                                    <span className={(showAutoGenerated&&(interaction.auto_questions.length>0)?'':'hide')}>[ <a onClick={() => _HandleShowAutoGenerate(showAutoGenerated)}>Esconder</a> ]</span> 
                                </div>
                                <div id="AutoGenerated" className={'clear ' + (showAutoGenerated?'':'hide ')}>
                                {
                                    interaction.auto_questions.map((item, i) => (
                                        <div key={'aquestion'+i} className="input-group"><input type="text" className="from form-control" id={'aquestion'+i} name={'aquestion'+i} value={item.question} onChange={(event) => _HandleChangeAutoQuestion(event, i)} /> <span className="input-group-append" > <button type="button" className="btn btn-info" onClick={() =>_HandleRemoveAutoQuestion(i)}> <i className="fa fa-trash"></i></button> </span></div>
                                    ))
                                }
                                </div>
                                <br />
                            </section>

                        </div>   
                    </div> 

                   


                    <div className="ibox float-e-margins">
                        <div className="ibox-content">
                            <section id="IAnswer">
                                <label>Tipo da resposta</label> <br />
                                <span className="tipo">
                                    <input type="radio" name="tipo" id="textual" defaultChecked={interaction.tipo===1} checked={interaction.tipo===1} onClick={()=>_HandleTipoClick(1)}/> <label htmlFor="textual">Textual</label>
                                    <input type="radio" name="tipo" id="opcoes" defaultChecked={interaction.tipo===0}  checked={interaction.tipo===0}  onClick={()=>_HandleTipoClick(0)}/> <label htmlFor="opcoes">Opções</label>
                                    <span className={'right ' + (interaction.tipo===1 && 'hide')} ><label htmlFor="isdefault">Interação inicial</label><input type="checkbox" name="isdefault" id="isdefault" defaultChecked={interaction.isdefault===1}  checked={interaction.isdefault===1}  onClick={_HandleDefaultClick}/></span>
                                </span>

                                <hr className="hr" />

                                <div id="answer_field_name" className={interaction.tipo===1?'':'hide'}>
                                    <label>Nome do campo <small>(para armazenamento em memória e post em action api)</small></label> <br />
                                    <input type="text" className="from form-control" id="name" name="name" value={interaction.name} onChange={(event) =>_HandleChange(event)}/>
                                    <br />
                                </div>
                                
                                

                                <div id="answer_textual" className={interaction.tipo===1?'':'hide'}>
                                    <label>Resposta</label> <br />
                                    <input type="text" className="from form-control" id="answer_phrase" name="answer_phrase" value={interaction.answers[0].phrase} onChange={(event) =>_HandleAnswerChange(event, 0, 'phrase')}/>
                                    <br />
                                </div>


                                <div id="answer_options" className={interaction.tipo===0?'':'hide'}>
                                    <label>Opções</label> <br />
                                    {
                                        interaction.answers.map((item, i) => (
                                            <div key={'op_io'+i} className="input-group m-b">
                                                <div className="input-group-prepend">
                                                    <button className="btn btn-white" type="button" onClick={(event) =>_HandleFocusOption(event, i)}>({i+1})</button>
                                                </div>
                                                <input type="text" className="from form-control" id={'option_phrase'+i} name={'option_phrase'+i} value={interaction.answers[i].phrase} onFocus={(event) =>_HandleFocusOption(event, i)} onChange={(event) =>_HandleChangeOption(event, i)}/>    
                                                <span className="input-group-append" > <button type="button" className={'btn btn-danger ' + (i===0 && 'hide')  } onClick={() =>_HandleRemoveOption(i)}> <i className="fa fa-trash"></i></button> <button type="button" className={'btn btn-primary ' + (i>0 && 'hide')  }onClick={_HandleAddOption}> adicionar</button> </span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </section>
                        </div>   
                    </div>

                   

                    <div className="ibox float-e-margins">
                        <div className="ibox-content">
                            <section id="SAnswer">
                                
                                {
                                    interaction.answers.map((item, i) => (
                                        <div key={'answers'+i} className={(optionSelected!==i&&'hide')}>
                                            <div className={interaction.tipo===1 && 'hide'}>
                                                <b>({optionSelected+1}). {interaction.answers[i].phrase}</b>
                                                <hr className="hr" />
                                            </div>

                                            <div className={interaction.tipo===0 && 'hide'}>
                                                <label>Modo de ação</label> <br />
                                                <select className="from form-control" id={'answer_mode'+i} name={'answer_mode'+i}  value={interaction.answers[i].mode}  onChange={(event) =>_HandleAnswerChange(event, i, 'mode')}>
                                                    <option value="0" selected={interaction.answers[i].mode==="0"}>Resposta</option>
                                                    <option value="1" selected={interaction.answers[i].mode==="1"}>Sem resposta</option>
                                                </select>
                                                <br />

                                                <label>Ação</label> <br />
                                                <select className="from form-control" id={'answer_action'+i} name={'answer_action'+i}  onChange={(event) =>_HandleAnswerChange(event, i, 'action')}>
                                                    <option value="0" selected={interaction.answers[i].action==="0"}>Nenhuma ação</option>
                                                    <option value="1" selected={interaction.answers[i].action==="1"}>Experar entrada expecífica</option>
                                                    <option value="2" selected={interaction.answers[i].action==="2"}>Redirecionar para nova resposta</option>
                                                    <option value="3" selected={interaction.answers[i].action==="3"}>Imprimir</option>
                                                    <option value="4" selected={interaction.answers[i].action==="4"}>Encerrar sessão de conversa</option>
                                                </select>
                                                <br />
                                            

                                                <label>Action Regex</label> <br />
                                                <div className="input-group m-b">
                                                    <div className="input-group-prepend">
                                                    <Dropdown Action={DropAction} Index={i}/>
                                                    </div>
                                                    <input type="text" className="from form-control" id={'answer_regex'+i}  name={'answer_regex'+i} value={interaction.answers[i].regex} onChange={(event) =>_HandleAnswerChange(event, i, 'regex')}/>    
                                                </div>
                                                <br />
                                            </div>
                                            
                                            <label>Action Api</label> <br />
                                            <input type="text" className="from form-control" id={'answer_api'+i} name={'answer_api'+i} value={interaction.answers[i].api} onChange={(event) =>_HandleAnswerChange(event, i, 'api')}/>
                                            <br />

                                            <label>Action Api Authorization Header</label> <br />
                                            <input type="text" className="from form-control" id={'answer_api_header'+i} name={'answer_api_header'+i} value={interaction.answers[i].api_header} onChange={(event) =>_HandleAnswerChange(event, i, 'api_header')}/>
                                          

                                            <hr className="hr" />

                                            
                                            <label>Intenção de destino</label> <br />
                                            <select className="from form-control" id={'trigger_category_id'+i}  name={'trigger_category_id'+i} onChange={(event) =>_HandleTriggerChange(event, i, 'trigger_category_id')}>
                                                <option value="">Nenhuma intenção selecionada</option>
                                                {
                                                    intents.length > 0 && intents.map((item, k) => (
                                                        <option value={item.intent_id} selected={item.intent_id===interaction.answers[i].trigger_category_id}>{item.name}</option>
                                                    ))
                                                }
                                            </select>

                                            <br />
                                            <label>Interação de destino</label> <br />
                                            <select className="from form-control" id={'trigger_id'+i} name={'trigger_id'+i} onChange={(event) =>_HandleAnswerChange(event, i, 'trigger_id')} disabled={interaction.answers[i].trigger_category_id===''}>
                                                {
                                                    interaction.answers[i]._triggers.length > 0 && interaction.answers[i]._triggers.map((item, k) => (
                                                        <option value={item.answer_id} selected={item.answer_id===interaction.answers[i].trigger_id}>{item.phrase}</option>
                                                    ))
                                                 }
                                            </select>

                                        
                                        </div>
                                    ))
                                }

                            </section>
                            <hr className="hr" />
                            <span className="left red">{errors.map((item, i) => (
                                <div key={'error'+i}>{item}<br /></div>
                            ))}</span>
                            <button type="button" className="btn btn-primary right" disabled={!saveable} onClick={_HandleSave}> &nbsp;&nbsp;Salvar Interação&nbsp;&nbsp; </button>

                            <div className="clear"></div>

                       </div>
                    </div>

                    
                    
                </div>

                <div className="col-lg-6">
                    <div id="layers_tree"></div>
                </div>
            </div>


            <Chat Agent={agent}/>
        </div>
    );
}