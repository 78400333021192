import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterObject } from '../../../scripts/ArrayUtils';
import './Cadastro.css';

import { Chat } from '../../Chat';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [agentId, setAgentId] = useState("");
    const [input, setInput] = useState({});
    const [greetings, setGreetings] = useState({initial: "", final:"", noresult:"", invalid_option: ""});
    const navigate = useNavigate();

    const _formAgentRef = useRef();
    const _agentIdRef = useRef();
    const _agentDataRef = useRef();
    const _agentRef = useRef(user);

    let { AgentId } = useParams();

    //#region Effects
    useEffect(() => {
        if (AgentId) {
            _agentIdRef.current = AgentId;
            setAgentId(AgentId);
        }

        _agentRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Loaders
 
    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_agentRef.current.token !== "" && _formAgentRef.current !== undefined) {
                    clearInterval(wait_token);

                    
                    if (_agentIdRef.current !== "" && _agentIdRef.current !== undefined && _agentIdRef.current != null) {
                        let agent_data = await new Request().Run("api/Agents/Load?experiment_id=" + _agentIdRef.current, "POST", {}, _agentRef.current.token);
                        _agentDataRef.current = agent_data;

                        let _initial = _agentDataRef.current.greetings.find(v => v.type === 0)?.phrase || "";
                        let _final = _agentDataRef.current.greetings.find(v => v.type === 1)?.phrase || "";
                        let _noresut = _agentDataRef.current.greetings.find(v => v.type === 2)?.phrase || "";
                        let _invalid_option = _agentDataRef.current.greetings.find(v => v.type === 3)?.phrase || "";


                        setGreetings({initial: _initial, final:_final, noresult:_noresut, invalid_option: _invalid_option});
                        await _formAgentRef.current.Load(agent_data);
                    }
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }



    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _handleInputChange = (e) => setInput({
        ...input,
        [e.currentTarget.name]: e.currentTarget.value
    })


    const _HandleChange = (event) => {
        let _greetings = Object.assign({}, greetings);

        _greetings[event.target.name] = event.target.value;
        setGreetings(_greetings);
    }


    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);

        let _greetings = Object.assign({}, greetings);
 
        let _result = await _formAgentRef.current.Post("api/Agents/Save", { experiment_id: agentId, initial: _greetings.initial, final: _greetings.final, noresult: _greetings.noresult,  invalid_option: _greetings.invalid_option }, user.token);
        setPageLoading(false);

        if (_result.toString() === "exists") {
            alert("Atendente já cadastrado!");
        } else {
            navigate('/Agents/Lista');
        }
    }
    //#endregion Handlers




   


    return (
        <div className="AgentCadastro">
            <div>
                <form name="fagent" onSubmit={(event) => _HandleSubmit('agent', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Atendente</h5>
                                </div>
                                <div className="ibox-content ibox-heading">
                                    <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                </div>

                                <div className="ibox-content">
                                    <div className="row m-b-lg">
                                        <FormGenerator ref={_formAgentRef} name={'user'} url={'/forms/agent.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                      
                                        <div style={{ paddingTop: '10px', paddingLeft:'5px' }}>
                                            <button className="btn btn-primary btn-sm" title="Salvar Atendente" type="submit" ><i className="fa fa-check"></i> Salvar Atendente</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Mensagens padrão</h5>
                                </div>


                                <div className="ibox-content">
                                    <label>Mensagem Inicial</label>
                                    <textarea id="initial" name="initial" className="form-control" value={greetings.initial} onChange={_HandleChange}>{greetings.initial} </textarea>    
                                    <br />
                                    <label>Mensagem Final</label>
                                    <textarea id="final" name="final" className="form-control" value={greetings.final} onChange={_HandleChange}>{greetings.final}</textarea>    
                                    <br />
                                    <label>Mensagem para ausencia de resultados</label>
                                    <textarea id="noresult" name="noresult" className="form-control" value={greetings.noresult} onChange={_HandleChange}>{greetings.final}</textarea> 
                                    <br />   
                                    <label>Mensagem para opção inválida</label>
                                    <textarea id="invalid_option" name="invalid_option" className="form-control" value={greetings.invalid_option} onChange={_HandleChange}>{greetings.invalid_option}</textarea> 
                                    <br />  
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <Chat Agent={{agent_id: agentId, name: _agentDataRef?.current?.name}}/>
        </div>
    );
}