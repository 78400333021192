import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from 'react-router-dom';
import './Lista.css';




export const Lista = () => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState({ word: "", uf_codigo: "", nivel: "" });
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);

    const navigate = useNavigate();

    const listRef = useRef();
    const pageRef = useRef();
    const totalRef = useRef();
    const niveisRef = useRef();
    const agentsPrestineRef = useRef(false);


    //#region Effects
    useEffect(() => {
        Init();
    }, [user]);


    useEffect(() => {
        if(isLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [isLoading]);
    //#endregion Effects


    //#region Loaders
    const Init = async() => {
        if(!agentsPrestineRef.current){
            agentsPrestineRef.current = true;
            _HandlerSearchChange(null);
        }
    }

    //#endregion Loaders


    //#region Handlers
    const _HandlerChange = (event) => {
        let _search = Object.assign({}, search);
        _search[event.target.id] = event.target.value;
        setSearch(_search);
    }


    const _HandlerSearchChange = (event) => {
        setPage(0);
        setOffset(0);
        pageRef.current = { page: 0, offset: 0 };

        listRef.current = [];
        setList(listRef.current);
        setIsLoading(true);
        setHasMore(true);

        Promise.resolve(new Request().Run("api/Agents/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word, nivel: search.nivel }, user.token))
            .then((data) => {
                setTotal(data.total);
                totalRef.current = data.total;
                let _list = [...data.list];
             
                listRef.current = listRef.current.concat(_list);
                setList(listRef.current);
                setIsLoading(false);
                if (listRef.current.length >= totalRef.current) {
                    setHasMore(false);
                } 
            });
    }


    const _fetchMoreData = () => {
        try {
            if (listRef.current.length >= totalRef.current) {
                setHasMore(false);
                return;
            }

            pageRef.current.page++;
            pageRef.current.offset++;
            setPage(pageRef.current.page);
            setOffset(pageRef.current.offset);
            setIsLoading(true);

            Promise.resolve(new Request().Run("api/Agents/List?page=" + pageRef.current.page + "&interval=50&offset=" + pageRef.current.offset, "POST", { word: search.word, nivel: search.nivel }, user.token))
                .then((data) => {
                    let _list = [...data.list];
                    listRef.current = listRef.current.concat(_list);
                    setList(listRef.current);
                    setIsLoading(false);
                });
        } catch (e) { }
    }


    const _UserClick = (item) => {
        navigate('/Agents/Cadastro/'+item.experiment_id);
    }


    const _HandlerEditAgent = (item) => {
        navigate('/Intents/Lista/'+item.experiment_id);
    }


    const _HandleNew = () => {
        navigate('/Agents/Cadastro');
    }


    const _HandlerTrain = (item) => {
        if(item.training === 0) {
            Promise.resolve(new Request().Run("api/Agents/Training?experiment_id=" + item.experiment_id, "GET", "", user.token))
                .then((data) => {
                    let _list = [...list];
                    _list.find(l => l.aexperiment_id === item.experiment_id).training = 1;

                    setList(_list);
                });
        }
    }
    //#endregion Handlers


    return (
        <div className="AgentsLista">
            <div className="row">
                <div className="col col-lg-8">
                    <div className="ibox float-e-margins">
                        <div className="ibox-title">
                            <h5>Lista de Atendentes Virtuais</h5>
                            <span className="pull-right">
                                <label className="label label-info btn" onClick={_HandleNew}> Novo atendente </label>
                                <label className="label label-default btn"> { total } atendentes </label>
                            </span>
                            
                        </div>

                        <div className="ibox-content">
                            <div className="input-group">
                                <input type="text" id="word" placeholder="Buscar por nome" value={search.word} className="input form-control" onChange={_HandlerChange} />

                                <span className="input-group-btn">
                                    <button id="btnBusca" type="button" className="btn btn btn-primary" onClick={_HandlerSearchChange}> <i className="fa fa-search"></i> Busca</button>
                                </span>
                            </div>

                            <div>
                                <InfiniteScroll
                                    dataLength={list.length}
                                    next={_fetchMoreData}
                                    hasMore={hasMore}
                                    loader={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Carregando...</b>
                                        </p>
                                    }
                                    style={{ display: 'flex', overflow: 'hidden', flexDirection: 'column' }}
                                    endMessage={
                                        <p style={{ textAlign: "center" }}>
                                            <b>Não há mais dados</b>
                                        </p>
                                    }>


                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Nome</th>
                                                <th>Guid</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {list.map((item, i) => (
                                                <tr key={'user' + i} className={(item.ativo===1?'':'risk')}>
                                                    <td className="tr_item" onClick={() => _UserClick(item)}> {item.name} </td>
                                                    <td className="tr_item" onClick={() => _UserClick(item)}> {item.guid} </td>
                                                    <td style={{ padding: '4px', margin: 0, width: '100px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _HandlerEditAgent(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className={'fa fa-flask' }></i> Intenções</button></td>
                                                    {
                                                        item.training === 0 && (<td style={{ padding: '4px', margin: 0, width: '120px' }}><button className="btn btn-danger btn-sm" type="button" onClick={() => _HandlerTrain(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className={'fas fa-comment-slash'}></i> Not trained</button></td>)
                                                    }

                                                    {
                                                        (item.training === 1 || item.training === 3) && (<td style={{ padding: '4px', margin: 0, width: '100px' }}><button className="btn btn-white btn-sm" type="button" onClick={() => _HandlerTrain(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className={'fas fa-comment-dots' }></i> Training</button></td>)
                                                    }
                                                    

                                                    {
                                                        item.training === 2 && (<td style={{ padding: '4px', margin: 0, width: '100px' }}><button className="btn btn-info btn-sm" type="button" onClick={() =>_HandlerTrain(item)} style={{ margin: '1px', padding: '1px', paddingLeft: '7px', paddingRight: '7px' }}><i className={'fa fa-check' }></i> Trained</button></td>)
                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </InfiniteScroll>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col col-lg-4">
                    
                </div>
            </div>
        </div>
    );
}