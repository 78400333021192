import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterObject } from '../../../scripts/ArrayUtils';
import './Cadastro.css';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [intentId, setIntentId] = useState("");
    const navigate = useNavigate();

    const _formIntentRef = useRef();
    const _intentIdRef = useRef();
    const _intentDataRef = useRef();
    const _intentRef = useRef(user);

    let { IntentId } = useParams();
    let { AgentId } = useParams();
    

    //#region Effects
    useEffect(() => {
        if (IntentId) {
            _intentIdRef.current = IntentId;
            setIntentId(IntentId);
        }

        _intentRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Loaders
 
    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_intentRef.current.token !== "" && _formIntentRef.current !== undefined) {
                    clearInterval(wait_token);

                    
                    if (_intentIdRef.current !== "" && _intentIdRef.current !== undefined && _intentIdRef.current != null) {
                        let agent_data = await new Request().Run("api/Intents/Load?category_id=" + _intentIdRef.current, "POST", {}, _intentRef.current.token);
                        _intentDataRef.current = agent_data;
                        await _formIntentRef.current.Load(agent_data);
                    }
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }



    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
 
        let _result = await _formIntentRef.current.Post("api/Intents/Save", { category_id: IntentId, experiment_id: AgentId }, user.token);
        setPageLoading(false);

        if (_result.toString() === "exists") {
            alert("Intenção já cadastrado!");
        } else {
            navigate('/Intents/Lista/'+ AgentId);
        }
    }
    //#endregion Handlers




   


    return (
        <div className="IntentsCadastro">
            <div>
                <form name="fintent" onSubmit={(event) => _HandleSubmit('intent', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Intenção</h5>
                                </div>
                                <div className="ibox-content ibox-heading">
                                    <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                </div>

                                <div className="ibox-content">
                                    <div className="row m-b-lg">
                                        <FormGenerator ref={_formIntentRef} name={'user'} url={'/forms/intent.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                      
                                        <div style={{ paddingTop: '10px', paddingLeft:'5px' }}>
                                            <button className="btn btn-primary btn-sm" title="Salvar Intenção" type="submit" ><i className="fa fa-check"></i> Salvar Intenção</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                           
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}