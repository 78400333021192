/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef, useContext } from 'react';
import $ from 'jquery';
import moment from 'moment';
import { Request } from '../../scripts/Request';
import { Uuid } from '../../scripts/StringUtils';
import './index.css';

import { Context } from '../../Context/AuthContext';
import { WaitObject } from '../../scripts/Wait';


export const Chat = (props) => {
    const component_name = "chat";
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [messages, setMessages] = useState([]);
    const [userId, setUserId] = useState(Uuid());
    const [chatLoading, setChatLoading] = useState(false);
    const {Agent} = props;


    const messageRef = useRef("");
    const firstLoadRef = useRef(false);
    const timerIdleRef = useRef(new Date());
    const agentRef = useRef(undefined);

    useEffect(()=> {
        if(!firstLoadRef.current) {
            firstLoadRef.current = true;
            console.log("Chat Rendered");
            
            
        }

        Init(); 

        return () => {

            if((new Date().getTime() - timerIdleRef.current.getTime()) > 1000) {
                try {
                    
                } catch(e) {}
            } 
        }

    }, []);


    useEffect(()=> { 
        AddListeners();
    }, [Agent]);


    //#region
    const Init = () => {

        // Open close small chat
        window.$('.open-small-chat').off();
        window.$('.open-small-chat').on('click', function (e) {
            e.preventDefault();
            window.$(this).children().toggleClass('fa-comments').toggleClass('fa-times');
            window.$('.small-chat-box').toggleClass('active');
        });

        // Initialize slimscroll for small chat
        window.$('.small-chat-box .content').slimScroll({
            height: '234px',
            railOpacity: 0.4
        });

        // Small todo handler
        window.$('.check-link').off();
        window.$('.check-link').on('click', function () {
            var button = $(this).find('i');
            var label = $(this).next('span');
            button.toggleClass('fa-check-square').toggleClass('fa-square-o');
            label.toggleClass('todo-completed');
            return false;
        });

        
    }
    //#endregion



    //#region Loaders
    const LoadChat = async(msg) => {
        await Promise.resolve(new Request().Run("api/Chat/Conversation", "POST", {experiment_id: Agent.agent_id, msg: msg, user_id:userId, name: "Eu"}, user.token))
        .then((data) => {
            if(data !== "" && data !== undefined && data !== null) {
               let msgs = [];
               /*data.map((item, i) => {
                    
               });*/

               msgs.push({origin: 1, message: data.join('\n')});

               /*let _messages = Object.assign({}, messages);
               _messages = _messages.concat(msgs)*/

               setMessages(prev => [ ...prev, ...msgs]);
               setTimeout(() => {
                    window.$('.small-chat-box .content').slimScroll({
                        scrollTo: window.$('.small-chat-box .content')[0].scrollHeight
                    });
                    messageRef.current.value = "";
                    setChatLoading(false);
                    window.$("#message").focus();
                }, 100);
            } 
        });
    }
    //#endregion Loaders


    //#region Handlers
    const _HandleSend = async() => {
        if(messageRef.current.value !== "")
        {
            setChatLoading(true);
            let _msg = Object.assign({},messageRef.current).value
            setMessages(prev => [ ...prev, {origin: 0, message: _msg }]);
            setTimeout(() => {
                window.$('.small-chat-box .content').slimScroll({
                    scrollTo: window.$('.small-chat-box .content')[0].scrollHeight
                });
                messageRef.current.value = "";
            }, 100);

            await LoadChat(_msg);
        }
       
    }
    //#endregion Handlers


    //#region Listeners
    const AddListeners = () =>{
        if(Agent) {
            var input = document.getElementById("message");

            try{
                input.removeEventListener("keypress");
            } catch(e) {}

            input.addEventListener("keypress", async function(event) {
                if (event.keyCode === 13 && Agent?.agent_id !== undefined &&  Agent?.agent_id !== "" && Agent?.name !== "" && Agent?.name !== undefined) {
                    await _HandleSend();
                }
            });
        }
    }
    //#endregion Listeners


    return (
        <div>
            <div className="small-chat-box fadeInRight animated">

                <div className="heading" draggable="true">
                    <small className="chat-date float-right">
                        CHATBOT
                    </small>
                    {Agent?.name}
                </div>

                <div className="content">

                    {/*<div className={'right'}>
                        <div className="author-name">
                            Eu <small className="chat-date"> {moment(new Date()).format('HH:mm')} </small>
                        </div>
                        <div className={'chat-message '}>
                            <span>
                            felipemartins@agenciavitrae.com.br
                            </span>
                        </div>
                    </div>*/}

                    {
                        messages.length > 0 && messages.map((item, i) => (
                            <div key={'msg'+i} className={item.origin===0?'right':'left'}>
                                <div className="author-name">
                                    {item.origin===0?'Eu':Agent?.name} <small className="chat-date"> {moment(new Date()).format('HH:mm')} </small>
                                </div>
                                <div className={'chat-message ' + (item.origin===0?'':'active')}>
                                    {item.message.split("\n").map((msg, idx) => (
                                         <span key={'line'+idx}>
                                         {msg}
                                         <br/>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ))

                        
                    }

                
                    <div className={'sk-spinner sk-spinner-three-bounce ' + (chatLoading?'':'hide')}>
                        <div className="sk-bounce1"></div>
                        <div className="sk-bounce2"></div>
                        <div className="sk-bounce3"></div>
                    </div>
                    
                </div>

              

                <div className="form-chat">
                    <div className="input-group input-group-sm"> 
                        <input id="message" name="message" type="text" className="form-control" ref={messageRef} /> 
                        <span className="input-group-append"> 
                            <button className="btn btn-primary" type="button" onClick={_HandleSend}>Enviar</button> 
                        </span>
                    </div>
                </div>

                </div>
                <div id="small-chat">
                {/*
                <span className="badge badge-warning float-right">5</span>
                */}
                <a className="open-small-chat">
                    <i className="fa fa-comments"></i>
                </a>

              
            </div>
        </div>
    )
}