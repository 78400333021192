import React, { useEffect,  useState, Suspense, lazy, useContext } from 'react';
import { Routes, Route,  useNavigate, useLocation, useParams } from 'react-router-dom';
import { Context } from './Context/AuthContext';
import Login  from './components/Login/Login';
import Home from './components/Admin/Home';
import { Lista as AgentsLista}  from './components/Admin/Agents/Lista';
import { Cadastro as AgentsCadastro}  from './components/Admin/Agents/Cadastro';
import { Lista as IntentsLista}  from './components/Admin/Intents/Lista';
import { Cadastro as IntentsCadastro}  from './components/Admin/Intents/Cadastro';
import { Lista as UsuariosLista}  from './components/Admin/Usuarios/Lista';
import { Cadastro as UsuariosCadastro}  from './components/Admin/Usuarios/Cadastro';
import Layout from './Layout.js';
import { Interactions } from './components/Admin/Interactions';
import { ChatFlow } from './components/Admin/ChatFlow';


export default function RouteManager() {
    const location = useLocation();
    const isLogin = (location.pathname.toLocaleLowerCase().indexOf("login") > -1 || location.pathname === "/");
    const navigate = useNavigate();

    const ProtectedRoute = ({ children }) => {
        const { loading, authenticated } = useContext(Context);
        if (!authenticated && !loading && !isLogin) {
           //return <Navigate to="/login" replace />;
           navigate("/login");
        } else {
            return children;
        }

      };


    return (
    
      <Suspense fallback={<div>Carregando...</div>}>
        <Layout>
            <Routes>
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/Home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                <Route exact path="/Agents/Lista" element={<ProtectedRoute><AgentsLista /></ProtectedRoute>} />
                <Route exact path="/Agents/Cadastro/" element={<ProtectedRoute><AgentsCadastro /></ProtectedRoute>} />
                <Route exact path="/Agents/Cadastro/:AgentId" element={<ProtectedRoute><AgentsCadastro /></ProtectedRoute>} />
                <Route exact path="/Intents/Lista/:AgentId" element={<ProtectedRoute><IntentsLista /></ProtectedRoute>} />
                <Route exact path="/Intents/Cadastro/:AgentId" element={<ProtectedRoute><IntentsCadastro /></ProtectedRoute>} />
                <Route exact path="/Intents/Cadastro/:AgentId/:IntentId" element={<ProtectedRoute><IntentsCadastro /></ProtectedRoute>} />
                <Route exact path="/Interactions/:IntentId" element={<ProtectedRoute><Interactions /></ProtectedRoute>} />
                <Route exact path="/ChatFlow/:IntentId" element={<ProtectedRoute><ChatFlow /></ProtectedRoute>} />
                <Route exact path="/Usuarios/Lista" element={<ProtectedRoute><UsuariosLista /></ProtectedRoute>} />
                <Route exact path="/Usuarios/Cadastro" element={<ProtectedRoute><UsuariosCadastro /></ProtectedRoute>} />
                <Route exact path="/Usuarios/Cadastro/:UsuarioId" element={<ProtectedRoute><UsuariosCadastro /></ProtectedRoute>} />
            </Routes>
        </Layout>
      </Suspense>
   
  );
}