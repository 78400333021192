import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from '../../../Context/AuthContext'
import $ from 'jquery';
import { Button, Modal } from "react-bootstrap";
import { Request } from '../../../scripts/Request';
import { FormGenerator } from '../../Form/FormGenerator';
import { useNavigate, useParams } from 'react-router-dom';
import { FilterObject } from '../../../scripts/ArrayUtils';
import './Cadastro.css';


export const Cadastro = (props) => {
    const { user, loading, authenticated, handleLogin, handleLogout } = useContext(Context);
    const [pageLoading, setPageLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const navigate = useNavigate();

    const _formUserRef = useRef();
    const _formLoadedRef = useRef();
    const _userIdRef = useRef();
    const _userDataRef = useRef();
    const _userRef = useRef(user);

    let { UsuarioId } = useParams();

    //#region Effects
    useEffect(() => {
        if (UsuarioId) {
            _userIdRef.current = UsuarioId;
            setUserId(UsuarioId);
        }

        _userRef.current = user;
    }, [user]);


    useEffect(() => {
        if(pageLoading){
            window.w_loadObj("body");
        } else {
            window.w_unloadAll();
        }
     }, [pageLoading]);

    //#endregion Effects


    //#region Loaders
 
    //#endregion Loaders


    //#region Form Handlers
    const OnFormLoaded = (fields) => {
        try {
            let wait_token = setInterval(async () => {
                if (_userRef.current.token !== "" && _formUserRef.current !== undefined) {
                    clearInterval(wait_token);

                    
                    if (_userIdRef.current !== "" && _userIdRef.current !== undefined && _userIdRef.current != null) {
                        let user_data = await new Request().Run("api/Usuarios/Load?user_id=" + _userIdRef.current, "POST", {}, _userRef.current.token);
                        _userDataRef.current = user_data;
                        await _formUserRef.current.Load(user_data);
                    } else {
                        await _formUserRef.current.Value("nivel", 1);
                    }
                }
            }, 100);
        } catch (e) {
            console.log(e);
        }

    }



    const Exec = (func, value) => {
        try {
            eval(func)(value);
        } catch (e) { }
    }
    //#endregion Form Handlers


    //#region Handlers
    const _HandleSubmit = async (formname, event) => {
        event.preventDefault();
        setPageLoading(true);
 
        let _result = await _formUserRef.current.Post("api/Usuarios/Save", { user_id: userId }, user.token);
        setPageLoading(false);

        if (_result.toString() === "exists") {
            alert("E-mail já cadastrado!");
        } else {
            navigate('/Usuarios/Lista');
        }
    }
    //#endregion Handlers




   


    return (
        <div className="UsuarioCadastro">
            <div>
                <form name="fuser" onSubmit={(event) => _HandleSubmit('usuario', event)} autoComplete="off">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="ibox float-e-margins">
                                <div className="ibox-title">
                                    <h5>Cadastro de Usuário</h5>
                                </div>
                                <div className="ibox-content ibox-heading">
                                    <small><i className="fa fa-stack-exchange"></i> Campos com * são obrigatórios. </small>
                                </div>

                                <div className="ibox-content">
                                    <div className="row m-b-lg">
                                        <FormGenerator ref={_formUserRef} name={'user'} url={'/forms/user.json'} execRef={Exec}></FormGenerator>
                                        <br />
                                      
                                        <div style={{ paddingTop: '10px', paddingLeft:'5px' }}>
                                            <button className="btn btn-primary btn-sm" title="Salvar Usuário" type="submit" ><i className="fa fa-check"></i> Salvar Usuário</button>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>


                        <div className="col-lg-4">
                           
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}