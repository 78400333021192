import React, { useState } from 'react';
import { Uuid } from '../../../scripts/StringUtils';


export const Dropdown = ({...props}) => {
    const [uuid, setUuid] = useState(Uuid())
    const [showDropdown, setShowDropdown] = useState(false);
    const { Action, Index } = props;

    const _HandleShowDropdown = () => {
        let r = !showDropdown;
        setShowDropdown(r);
        
        if(r) {
            window.$("#"+uuid+".dropdown-menu").show();
        } else {
            window.$("#"+uuid+".dropdown-menu").hide();
        }
    }

    const _HandlerClick = (txt) => {
        window.$("#"+uuid+".dropdown-menu").hide();
        setShowDropdown(false);
        Action(txt, Index);
    }

    return (
        <div className="Dropdown">
            <button data-toggle="dropdown" className="btn btn-white dropdown-toggle" type="button" onClick={_HandleShowDropdown}>Modelos </button>
            <ul id={uuid} className="dropdown-menu">
                <li><a onClick={()=>_HandlerClick("^([\w\.\-]+)@([\w\-]+)((\.(\w){2,3})+)$")}>E-mail</a></li>
                <li><a onClick={()=>_HandlerClick("^(\(11\) [9][0-9]{4}-[0-9]{4})|(\(1[2-9]\) [5-9][0-9]{3}-[0-9]{4})|(\([2-9][1-9]\) [5-9][0-9]{3}-[0-9]{4})$")}>Telefone</a></li>
                <li><a onClick={()=>_HandlerClick("^\d{5}-\d{3}$")}>CEP</a></li>
                <li className="dropdown-divider"></li>
                <li><a onClick={()=>_HandlerClick("[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}")}>Documento CPF</a></li>
                <li><a onClick={()=>_HandlerClick("[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2}")}>Documento CNPJ</a></li>
            </ul>
        </div>
    )
}