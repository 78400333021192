import React from 'react';
import './BreadCrumb.css';


export default function Breadcrumb({...props}) {
    const { title, list } = props;


    return (
        <span className="Breadcrumb">
            <h5><b>{title}</b></h5>  &nbsp; <i className="fa fa-angle-right"></i> &nbsp;
            {
                list.map((item, i) => (
                    <span key={'bc'+i}>
                    <span className={(i+2)%2?'underline':''}>
                        { item.url !== "" ? (<a href={item.url}>{item.name}</a>) : item.name } </span> 
                        
                        {i<(list.length-1) ? <span>&nbsp;<i className="fa fa-angle-right"></i>&nbsp;</span> : ''} 
                    </span>
                ))
            }
        
   
        </span>
    )
}